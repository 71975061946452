import { FC, useContext, useEffect, useState } from "react";
import Modal from "../common/modal";
import { FormProps } from ".";
import { AuthContext, UserPayload } from "@/context/AuthContext";
// import { SERVER_API_URL } from "@/constants/server";
import Button from "../common/button";
import { passwordFormEN } from "@/assets/dictionaries/en/sign-in-content";
import { passwordFormES } from "@/assets/dictionaries/es/sign-in-content";

enum Alerts {
  NO_ALERT,
  USER_DOES_NOT_EXISTS,
  SERVER_ERROR,
  SUCCESS,
  LOADING,
}

const SERVER_API_URL = process.env.NEXT_PUBLIC_SERVER_API_URL;

const RecoveryForm: FC<FormProps> = ({ changeForm, onSuccess }) => {
  // const { logIn } = useContext(AuthContext);

  const [formData, setFormData] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState({
    email: "",
  });
  const [showAlert, setShowAlert] = useState(Alerts.NO_ALERT);

  const [passwordRequestContent, setPasswordRequestContent] =
    useState(passwordFormEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setPasswordRequestContent(passwordFormES);
        break;
      case "EN":
      default:
        setPasswordRequestContent(passwordFormEN);
        break;
    }
  }, []); // Only run on mount

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.id]: "" }); // Remove the error on type
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = {
      email: "",
    };

    let login_errors = 0;

    if (!formData.email) {
      errors.email = passwordRequestContent.error_email_1;
      login_errors = login_errors + 1;
    } else {
      if (!formData.email.match(/^[\w-.]+(\+[\w-]*)?@([\w-]+\.)+[\w-]{2,4}$/)) {
        errors.email = passwordRequestContent.error_email_2;
        login_errors = login_errors + 1;
      }
    }

    if (login_errors != 0) {
      setFormErrors(errors);
      return;
    }

    const credentials = {
      email: formData.email,
    };

    const passwordRequest = async () => {
      try {
        const res = await fetch(
          `${SERVER_API_URL}/api/v1/users/password/reset/request`,
          {
            body: JSON.stringify(credentials),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          },
        );

        if (res.status >= 200 && res.status <= 299) {
          const data = await res.json();

          setShowAlert(Alerts.SUCCESS);
        } else {
          const error = await res.json();
          const detail: string = error["detail"];

          if (detail.includes("exist")) {
            setShowAlert(Alerts.USER_DOES_NOT_EXISTS);
          } else {
            setShowAlert(Alerts.SERVER_ERROR);
          }
        }
      } catch (e) {
        setShowAlert(Alerts.SERVER_ERROR);
      }
    };

    passwordRequest();
  };

  return (
    <div>
      <p className="text-3xl font-bold text-center">
        {passwordRequestContent.title_recovery}
      </p>
      <p className="mt-2 mb-4 text-xl text-slate-400 text-center">
        {passwordRequestContent.recovery_desc}
      </p>

      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
        <label className="text-slate-400 font-bold flex flex-col gap-2">
          {passwordRequestContent.recovery_email_label}
          <input
            className="border-2 rounded-lg p-2 w-full"
            placeholder={passwordRequestContent.recovery_email_address}
            type="text"
            id="email"
            value={formData.email}
            onChange={handleChange}
            autoFocus={true}
          />
          <p
            className={
              formErrors.email ? "text-red-500" : "text-transparent select-none"
            }
          >
            {formErrors.email || "z"}
          </p>
        </label>

        <p className="mt-2 mb-6 text-slate-400">
          {passwordRequestContent.login_account_1}
          <button
            onClick={() => changeForm("login")}
            className="text-gbci-accent font-bold"
          >
            {passwordRequestContent.login_account_2}
          </button>{" "}
          {passwordRequestContent.login_account_3}
        </p>

        <Button size="large" type="submit">
          {passwordRequestContent.button_request}
        </Button>
      </form>

      {showAlert !== Alerts.NO_ALERT && (
        <div
          className={`mt-8 p-4 ${showAlert == Alerts.SUCCESS ? "text-green-400 bg-green-100 border border-green-400" : "text-red-400 bg-red-100 border border-red-400"} rounded-md`}
        >
          <p>
            {showAlert == Alerts.USER_DOES_NOT_EXISTS
              ? passwordRequestContent.alert_1
              : showAlert == Alerts.SERVER_ERROR
                ? passwordRequestContent.alert_2
                : showAlert == Alerts.SUCCESS
                  ? passwordRequestContent.alert_3
                  : passwordRequestContent.alert_4}
          </p>
        </div>
      )}
    </div>
  );
};

export default RecoveryForm;
