interface cookiesBannerEN {
  cookies_desc_1: string;
  cookies_desc_2: string;
  cookies_desc_3: string;
  button_accept: string;
  button_read: string;
}

export const cookiesBannerEN: cookiesBannerEN = {
  cookies_desc_1:
    "This website uses third party cookies to enhance user experience. You an read more about them in our ",
  cookies_desc_2: " privacy policy",
  cookies_desc_3:
    ". Do you accept the terms and conditions with the use of cookies?",
  button_accept: "Accept all cookies",
  button_read: "Read more",
};

interface cookiesModalEN {
  header: string;
  date: string;
  desc: string;
  sub_title_1: string;
  text_1_1: string;
  text_1_2: string;
  text_1_3: string;
  sub_title_2: string;
  text_2_1: string;
  text_2_2: string;
  text_2_3: string;
  text_2_4: string;
  sub_title_3: string;
  text_3_1: string;
  text_3_2: string;
  sub_title_4: string;
  text_4_1: string;
  text_4_2: string;
  text_4_3: string;
  sub_title_5: string;
  text_5_1: string;
  sub_title_6: string;
  text_6_1: string;
  sub_title_7: string;
  text_7_1: string;
  sub_title_8: string;
  text_8_1: string;
  button_close: string;
}

export const cookiesModalEN: cookiesModalEN = {
  header: "Privacy Policy",
  date: "Effective Date: July 7, 2024",
  desc: "Thank you for visiting the GBCI Group's website (“we”, “our”, “us”). Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.gbcigroup.com], including any other media form, media channel, mobile website, or mobile application related or connected to the website. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the website.",
  sub_title_1: "Information we collect",
  text_1_1:
    "We may collect information about you in a variety of ways. The information we may collect on the website includes:",
  text_1_2:
    "- Personal Data: Personally identifiable information, such as your name, business name, business address, email address, and information about your business, all of this is information that you voluntarily give to us when you choose to participate in various activities related to the website, such as contact requests or assessment results requests.",
  text_1_3:
    "- Derivative Data: Information our servers automatically collect when you access the website, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the website.",
  sub_title_2: "Use of your information",
  text_2_1:
    "Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the website to:",
  text_2_2:
    "- Compile anonymous statistical data and analysis for use internally or with third parties.",
  text_2_3: "- Improve the functionality of our website.",
  text_2_4:
    "- Monitor and analyze usage and trends to improve your experience with the website.",
  sub_title_3: "Disclosure of your information",
  text_3_1:
    "We may share information we have collected about you in certain situations. Your information may be disclosed as follows:",
  text_3_2:
    "- By Law or to Protect Rights: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.",
  sub_title_4: "Third-party service providers",
  text_4_1:
    "We may use third-party service providers to service various aspects of the website. Each third-party service provider's use of your personal information is dictated by their respective privacy policies.",
  text_4_2:
    "- YouTube Videos: We embed videos from our official YouTube channel using YouTube's privacy-enhanced mode. This mode may set cookies on your computer once you click on the YouTube video player, but YouTube will not store personally identifiable cookie information for playbacks of embedded videos using the privacy-enhanced mode.",
  text_4_3:
    "- LinkedIn Insight Tag: We use the LinkedIn Insight Tag to track conversions, retarget website visitors, and gain insights about how LinkedIn members use our website. The LinkedIn Insight Tag enables the collection of metadata such as IP address information, timestamp, and events (e.g., page views). All data is encrypted. LinkedIn does not share personal data with the website owner; it only provides aggregated reports about website audience and ad performance.",
  sub_title_5: "Security of your information",
  text_5_1:
    "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.",
  sub_title_6: "Policy for children",
  text_6_1:
    "We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.",
  sub_title_7: "Changes to this privacy policy",
  text_7_1:
    "We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.",
  sub_title_8: "Contact us",
  text_8_1:
    "If you have questions or comments about this Privacy Policy, please contact us at this email: contact@gbcigroup.com. You can also call us to our contact telephone: +52 833 160 8894.",
  button_close: "Close",
};
