interface HeaderBarEN {
  header_home: string;
  header_tools: string;
  header_games: string;
  header_about_us: string;
  header_contact: string;
  header_profile: string;
  header_sign_in: string;
  header_sign_out: string;
  tools_available: Record<string, string>[];
}

export const headerBarEN: HeaderBarEN = {
  header_home: "Home",
  header_tools: "Tools",
  header_games: "Games",
  header_about_us: "About us",
  header_contact: "Contact",
  header_profile: "Profile",
  header_sign_in: "Sign In",
  header_sign_out: "Sign out",
  tools_available: [{ "Impetus Selection Assessment": "/tools/isa" }],
};
