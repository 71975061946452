import { createContext } from "react";

export interface UserPayload {
  id: string;
  name: string;
  last_name: string;
  email: string;
  phone_country_zone: string;
  phone: string;
  photo_url?: string;
}

export interface IAuthObject {
  token: string | null;
  isLoggedIn: boolean;
  currentUser: UserPayload | null;
  remember: string | null;
}

export interface IAuthContext {
  auth: IAuthObject;
  logIn: (token: string, currentUser: UserPayload, remember: string) => void;
  logOut: () => void;
  updateToken: (token: string) => void;
  updateCurrentUserDetail: (
    detail: keyof UserPayload,
    value: string | undefined,
  ) => void;
}

export const AuthContext = createContext<IAuthContext>({
  auth: {
    token: null,
    isLoggedIn: false,
    currentUser: null,
    remember: null,
  },
  logIn: () => {},
  logOut: () => {},
  updateToken: () => {},
  updateCurrentUserDetail: () => {},
});
