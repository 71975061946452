import {
  AuthContext,
  IAuthContext,
  IAuthObject,
  UserPayload,
} from "@/context/AuthContext";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";

const _safeReadFromLocalStorage = (key: string) => {
  try {
    const stored = localStorage.getItem(key);
    return stored || null; // Always return null if not found
  } catch (e) {
    return null;
  }
};

const _safeReadFromSessionStorage = (key: string) => {
  try {
    const stored = sessionStorage.getItem(key);
    return stored || null; // Always return null if not found
  } catch (e) {
    return null;
  }
};

const _safeJSONParse = (str: string | null) => {
  if (str === null) return null;

  try {
    return JSON.parse(str) || null; // Always return null if parsing fails
  } catch (e) {
    return null;
  }
};

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState<IAuthObject>({
    token:
      _safeReadFromSessionStorage("token") ||
      _safeReadFromLocalStorage("token"),
    isLoggedIn: true,
    currentUser:
      _safeJSONParse(_safeReadFromSessionStorage("currentUser")) ||
      _safeJSONParse(_safeReadFromLocalStorage("currentUser")),
    remember:
      _safeReadFromSessionStorage("remember") ||
      _safeReadFromLocalStorage("remember"),
  });

  // Copy data from localStorage to sessionStorage if it exists
  useEffect(() => {
    const token = _safeReadFromLocalStorage("token");
    const currentUser = _safeReadFromLocalStorage("currentUser");
    const remember = _safeReadFromLocalStorage("remember");

    // Check if session storage is empty and copy from local storage
    if (!sessionStorage.getItem("token") && token) {
      sessionStorage.setItem("token", token);
    }
    if (!sessionStorage.getItem("currentUser") && currentUser) {
      sessionStorage.setItem("currentUser", currentUser);
    }
    if (!sessionStorage.getItem("remember") && remember) {
      sessionStorage.setItem("remember", remember);
    }
  }, []);

  const updateCurrentUserDetail = (
    detail: keyof UserPayload,
    value: string | undefined,
  ) => {
    setAuth(
      (prev) =>
        ({
          ...prev,
          currentUser: { ...prev.currentUser, [detail]: value },
        }) as IAuthObject,
    );
  };

  const logIn = (token: string, currentUser: UserPayload, remember: string) => {
    setAuth({
      token,
      isLoggedIn: true,
      currentUser,
      remember,
    });

    // Check if remember is "false" and only update sessionStorage
    if (remember === "false") {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
      sessionStorage.setItem("remember", remember);
    } else {
      // If remember is true, also update localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      localStorage.setItem("remember", remember);

      // Also update sessionStorage
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
      sessionStorage.setItem("remember", remember);
    }
  };

  const logOut = () => {
    setAuth({
      token: null,
      isLoggedIn: false,
      currentUser: null,
      remember: null,
    });

    // Clear data from both localStorage and sessionStorage
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("remember");

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("remember");
  };

  const updateToken = (token: string) => {
    setAuth((prev) => ({ ...prev, token }));
  };

  useEffect(() => {
    if (!auth.token) {
      setAuth((prev) => ({ ...prev, isLoggedIn: false }));
    }
  }, []);

  useEffect(() => {
    if (auth.token) {
      // Update localStorage only if remember is not "false"
      if (auth.remember !== "false") {
        localStorage.setItem("token", auth.token || "");
        localStorage.setItem(
          "currentUser",
          JSON.stringify(auth.currentUser) || "",
        );
        localStorage.setItem("remember", auth.remember || "");
      }

      // Always update sessionStorage
      sessionStorage.setItem("token", auth.token || "");
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify(auth.currentUser) || "",
      );
      sessionStorage.setItem("remember", auth.remember || "");
    }
  }, [auth]);

  const value: IAuthContext = useMemo(
    () => ({ auth, logIn, logOut, updateToken, updateCurrentUserDetail }),
    [auth],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
