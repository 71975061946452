interface loginFormEN {
  title_section_verify: string;
  verify_desc: string;
  verification_code_label: string;
  verify_code: string;
  verify_button: string;

  error_code: string;

  title_login: string;
  login_desc_1: string;
  login_desc_2: string;
  login_desc_3: string;
  login_email_label: string;
  login_email_address: string;
  login_password_label: string;
  login_password: string;
  show_password: string;
  hide_password: string;
  keep_login: string;
  forgot_password: string;

  alert_1: string;
  alert_2: string;
  alert_3: string;
  alert_4: string;

  error_email_1: string;
  error_email_2: string;

  error_password_1: string;
}

export const loginFormEN: loginFormEN = {
  title_section_verify: "Verify login",
  verify_desc: "Please check your email for the verification code",
  verification_code_label: "VERIFICATION CODE:",
  verify_code: "Verification code",
  verify_button: "Verify login",

  error_code: "Please enter your verification code.",

  title_login: "Login",
  login_desc_1: "Enter your credentials below or ",
  login_desc_2: "sign up",
  login_desc_3: "for a new account.",
  login_email_label: "EMAIL:",
  login_email_address: "Email address",
  login_password_label: "PASSWORD:",
  login_password: "Password",
  show_password: "Show password",
  hide_password: "Hide password",
  keep_login: "Keep me logged in",
  forgot_password: "Forgot your password?",

  alert_1: "User is not registered, please register.",
  alert_2: "Email or password is not correct, retry.",
  alert_3: "Server error. Please try again.",
  alert_4: "Verification code is not correct, retry.",

  error_email_1: "Please enter your email.",
  error_email_2: "Please enter a valid email address.",

  error_password_1: "Please enter your password.",
};

interface passwordFormEN {
  title_recovery: string;
  recovery_desc: string;
  recovery_email_label: string;
  recovery_email_address: string;

  login_account_1: string;
  login_account_2: string;
  login_account_3: string;

  button_request: string;

  alert_1: string;
  alert_2: string;
  alert_3: string;
  alert_4: string;

  error_email_1: string;
  error_email_2: string;
}

export const passwordFormEN: passwordFormEN = {
  title_recovery: "Password Recovery",
  recovery_desc: "Enter your email to request a password reset",
  recovery_email_label: "EMAIL:",
  recovery_email_address: "Email address",

  login_account_1: "Already got your password? ",
  login_account_2: "log in",
  login_account_3: "with your account.",

  button_request: "Send request",

  alert_1: "User not found.",
  alert_2: "User not found.",
  alert_3: "Password reset request sent!",
  alert_4: "Server error. Please try again.",

  error_email_1: "Please enter your email.",
  error_email_2: "Please enter a valid email address.",
};

interface registerFormEN {
  error_name: string;
  error_last_name: string;
  error_email_1: string;
  error_email_2: string;
  error_password_1: string;
  error_password_2: string;
  error_password_3: string;
  error_password_4: string;
  error_password_5: string;
  error_password_6: string;
  error_confirm_password_1: string;
  error_confirm_password_2: string;
  error_phone_number_1: string;
  error_phone_number_2: string;

  error_verification: string;

  verify_account_title: string;
  verify_account_desc: string;
  verification_code_label: string;
  verification_code: string;
  button_verify: string;

  register_title: string;
  register_desc_1: string;
  register_desc_2: string;
  register_desc_3: string;
  register_name_label: string;
  register_name: string;
  register_last_name_label: string;
  register_last_name: string;
  register_phone_number_label: string;
  register_phone_number: string;
  register_email_label: string;
  register_email: string;
  register_password_label: string;
  register_password: string;
  register_confirm_password_label: string;
  register_confirm_password: string;
  show_password: string;
  hide_password: string;
  verify_desc_1: string;
  verify_desc_2: string;
  button_create: string;
  alert_1: string;
  alert_2: string;
  alert_3: string;
  alert_4: string;
}

export const registerFormEN: registerFormEN = {
  error_name: "Please enter your name.",
  error_last_name: "Please enter your last name.",
  error_email_1: "Please enter your email.",
  error_email_2: "Please enter a valid email address.",
  error_password_1: "Please enter your password.",
  error_password_2: "Password must be at least 8 characters long.",
  error_password_3: "Password must contain at least one lowercase letter.",
  error_password_4: "Password must contain at least one uppercase letter.",
  error_password_5: "Password must contain at least one digit.",
  error_password_6:
    "Password must contain at least one of the following symbols: @$!?#%*?&",
  error_confirm_password_1: "Please confirm password.",
  error_confirm_password_2: "Password is not the same.",
  error_phone_number_1: "Please enter your phone.",
  error_phone_number_2: "Phone number must be at least 10 characters long.",

  error_verification: "Please enter your verification code.",

  verify_account_title: "Verify account",
  verify_account_desc:
    "Please check your email for the verification code and verify your email to finish creating your account",
  verification_code_label: "VERIFICATION CODE:",
  verification_code: "Verification code",
  button_verify: "Verify account",

  register_title: "Register",
  register_desc_1: "Create your account or ",
  register_desc_2: "log in",
  register_desc_3: "if you already have an account",
  register_name_label: "NAME:",
  register_name: "Name",
  register_last_name_label: "LAST NAME:",
  register_last_name: "Last Name",
  register_phone_number_label: "PHONE NUMBER:",
  register_phone_number: "Phone Number",
  register_email_label: "EMAIL:",
  register_email: "Email address",
  register_password_label: "PASSWORD:",
  register_password: "Password",
  register_confirm_password_label: "CONFIRM PASSWORD:",
  register_confirm_password: "Confirm Password",
  show_password: "Show password",
  hide_password: "Hide password",
  verify_desc_1: "Already registered? ",
  verify_desc_2: "Verify account",
  button_create: "Create account",
  alert_1: "User already exists. Please log in.",
  alert_2: "Verification code is not correct, retry.",
  alert_3: "Server error. Please try again.",
  alert_4: "Server error. Please try again.",
};

interface resendFormEN {
  error_email_1: string;
  error_email_2: string;
  resend_title: string;
  resend_desc: string;
  resend_email_label: string;
  resemd_email: string;
  button_resend: string;
  alert_1: string;
  alert_2: string;
}

export const resendFormEN: resendFormEN = {
  error_email_1: "Please enter your email.",
  error_email_2: "Please enter a valid email address.",
  resend_title: "Verify account",
  resend_desc: "Provide your email to re-send the verification code",
  resend_email_label: "EMAIL:",
  resemd_email: "Email address",
  button_resend: "Send verification code",
  alert_1: "User does not exist, please sign up.",
  alert_2: "Server error. Please try again.",
};

interface verifyFormEN {
  error_email_1: string;
  error_email_2: string;
  error_verify_code: string;
  verify_title: string;
  verify_desc: string;
  verify_email_label: string;
  verify_email: string;
  verify_code_label: string;
  verify_code: string;
  button_verify: string;
  alert_1: string;
  alert_2: string;
  alert_3: string;
}

export const verifyFormEN: verifyFormEN = {
  error_email_1: "Please enter your email.",
  error_email_2: "Please enter a valid email address.",
  error_verify_code: "Please enter your verification code.",
  verify_title: "Verify account",
  verify_desc:
    "Please check you email for the verification code and verify your email to finish creating your account",
  verify_email_label: "EMAIL:",
  verify_email: "Email address",
  verify_code_label: "VERIFICATION CODE:",
  verify_code: "Verification code",
  button_verify: "Verify account",
  alert_1: "User does not exist, please sign up.",
  alert_2: "Verification code is incorrect, please retry.",
  alert_3: "Server error. Please try again.",
};
