"use client"; // Ensure this component runs only on the client side

import { useEffect, useState } from "react";

type Option = "EN" | "ES";

const DropdownButton = () => {
  // State to track the selected value, defaulting to 'EN' initially
  const [selectedValue, setSelectedValue] = useState<Option>("EN");

  // State to manage dropdown visibility
  const [isOpen, setIsOpen] = useState(false);

  // State to track if the component has finished loading from localStorage
  const [isLoaded, setIsLoaded] = useState(false);

  // Step 1: Use useEffect to load from localStorage after the component has mounted
  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedValue = localStorage.getItem("selectedOption");
      if (storedValue) {
        setSelectedValue(storedValue as Option); // Cast to Option type
      }
      setIsLoaded(true); // Set the loading state to true after checking localStorage
    }
  }, []); // Empty dependency array ensures this runs only once, after the component mounts

  // Step 2: Handle selection change and update localStorage
  const handleSelectChange = (value: Option) => {
    setSelectedValue(value);
    if (typeof window !== "undefined") {
      localStorage.setItem("selectedOption", value); // Store the selected value in localStorage
    }
    setIsOpen(false); // Close the dropdown after selection
    window.location.reload();
  };

  // Step 3: Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Step 4: Render the component only if the value has been loaded from localStorage
  if (!isLoaded) {
    return null; // Alternatively, you could render a loading spinner here if desired
  }

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="px-2 py-1 fixed top-0 left-0 bg-gbci-accent text-white rounded-br-lg focus:outline-none"
      >
        Lang: {selectedValue}
      </button>
      {isOpen && (
        <div className="fixed left-0 -mt-[64px] lg:-mt-[68px] bg-white shadow-lg rounded-br-lg">
          <ul className="py-1">
            {["EN", "ES"].map((option) => (
              <li
                key={option}
                onClick={() => handleSelectChange(option as Option)}
                className="px-4 py-2 text-gray-800 hover:bg-gbci-accent/25 cursor-pointer"
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
