interface HeaderBarES {
  header_home: string;
  header_tools: string;
  header_games: string;
  header_about_us: string;
  header_contact: string;
  header_profile: string;
  header_sign_in: string;
  header_sign_out: string;
  tools_available: Record<string, string>[];
}

export const headerBarES: HeaderBarES = {
  header_home: "Inicio",
  header_tools: "Herramientas",
  header_games: "Juegos",
  header_about_us: "Nosotros",
  header_contact: "Contacto",
  header_profile: "Perfil",
  header_sign_in: "Iniciar sesión",
  header_sign_out: "Cerrar sesión",
  tools_available: [{ "Evaluación de Selección de Impetus": "/tools/isa" }],
};
