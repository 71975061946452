import { FC, useContext, useEffect, useState } from "react";
import Modal from "../common/modal";
import { FormProps } from ".";
import { AuthContext, UserPayload } from "@/context/AuthContext";
import Button from "../common/button";
import React from "react";
import { loginFormEN } from "@/assets/dictionaries/en/sign-in-content";
import { loginFormES } from "@/assets/dictionaries/es/sign-in-content";

enum Alerts {
  NO_ALERT,
  WRONG_PASS,
  USER_NOT_REGISTERED,
  SERVER_ERROR,
}
const SERVER_API_URL = process.env.NEXT_PUBLIC_SERVER_API_URL;

const LoginForm: FC<FormProps> = ({ changeForm, onSuccess }) => {
  const { logIn } = useContext(AuthContext);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  const [disableButtonSecret, setDisableButtonSecret] =
    useState<boolean>(false);
  const [formDataSecret, setFormDataSecret] = useState({ client_secret: "" });
  const [formErrorsSecret, setFormErrorsSecret] = useState({
    client_secret: "",
  });

  const [showTokenfield, setShowTokenfield] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(Alerts.NO_ALERT);

  const [loginFormContent, setLoginFormContent] = useState(loginFormEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setLoginFormContent(loginFormES);
        break;
      case "EN":
      default:
        setLoginFormContent(loginFormEN);
        break;
    }
  }, []); // Only run on mount

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.id]: "" }); // Remove the error on type
  };

  const handleChangeSecret = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormDataSecret({ ...formDataSecret, [e.target.id]: e.target.value });
    setFormErrorsSecret({ ...formErrorsSecret, [e.target.id]: "" }); // Remove the error on type
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmitRequest = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = {
      username: "",
      password: "",
    };

    let login_errors = 0;

    if (!formData.username) {
      errors.username = loginFormContent.error_email_1;
      login_errors = login_errors + 1;
    } else {
      if (
        !formData.username.match(/^[\w-.]+(\+[\w-]*)?@([\w-]+\.)+[\w-]{2,4}$/)
      ) {
        errors.username = loginFormContent.error_email_2;
        login_errors = login_errors + 1;
      }
    }

    if (!formData.password) {
      errors.password = loginFormContent.error_password_1;
      login_errors = login_errors + 1;
    }

    if (login_errors != 0) {
      setFormErrors(errors);
      return;
    }

    setDisableButton(true);

    const credentials = {
      username: formData.username,
      password: formData.password,
    };

    const loginUser = async () => {
      try {
        const res = await fetch(
          `${SERVER_API_URL}/api/v1/users/login/request`,
          {
            body: JSON.stringify(credentials),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          },
        );

        if (res.status >= 200 && res.status <= 299) {
          const data = await res.json();
          // logIn(
          //   `Bearer ${data["access_token"]}`,
          //   data["payload"] as UserPayload,
          // );
          setShowAlert(Alerts.NO_ALERT);
          setShowTokenfield(true);
          setDisableButton(false);
          // onSuccess();
        } else {
          const error = await res.json();
          const detail: string = error["detail"];
          setDisableButton(false);
          if (detail.includes("exist")) {
            setShowAlert(Alerts.USER_NOT_REGISTERED);
          } else {
            setShowAlert(Alerts.WRONG_PASS);
          }
        }
      } catch (e) {
        setDisableButton(false);
        setShowAlert(Alerts.SERVER_ERROR);
      }
    };

    loginUser();
  };

  const handleSubmitVerify = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let remember;
    const errors = {
      client_secret: "",
    };

    let login_errors = 0;

    if (!formDataSecret.client_secret) {
      errors.client_secret = loginFormContent.error_code;
      login_errors = login_errors + 1;
    }

    if (login_errors != 0) {
      setFormErrorsSecret(errors);
      return;
    }

    setDisableButtonSecret(true);

    const credentials = {
      username: formData.username,
      password: formData.password,
      client_secret: formDataSecret.client_secret,
    };

    const verifyloginUser = async () => {
      try {
        const res = await fetch(`${SERVER_API_URL}/api/v1/users/login/verify`, {
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (res.status >= 200 && res.status <= 299) {
          const data = await res.json();

          if (keepLoggedIn) {
            remember = "true";
          } else {
            remember = "false";
          }
          logIn(
            `Bearer ${data["access_token"]}`,
            data["payload"] as UserPayload,
            remember,
          );
          setShowAlert(Alerts.NO_ALERT);
          setDisableButtonSecret(false);
          onSuccess();
        } else {
          const error = await res.json();
          const detail: string = error["detail"];
          setDisableButtonSecret(false);
          if (detail.includes("exist")) {
            setShowAlert(Alerts.USER_NOT_REGISTERED);
          } else {
            setShowAlert(Alerts.WRONG_PASS);
          }
        }
      } catch (e) {
        setShowAlert(Alerts.SERVER_ERROR);
      }
    };

    verifyloginUser();
  };

  return (
    <div>
      {showTokenfield ? (
        <>
          <p className="text-3xl font-bold text-center">
            {loginFormContent.title_section_verify}
          </p>
          <p className="mt-2 mb-4 text-xl text-slate-400 text-center">
            {loginFormContent.verify_desc}
          </p>
          <form
            className="w-full flex flex-col gap-4"
            onSubmit={handleSubmitVerify}
          >
            <label className="text-slate-400 font-bold flex flex-col gap-2">
              {loginFormContent.verification_code_label}
              <input
                className="border-2 rounded-lg p-2 w-full"
                placeholder={loginFormContent.verify_code}
                type="text"
                id="client_secret"
                value={formDataSecret.client_secret}
                onChange={handleChangeSecret}
                autoFocus={true}
              />
              <p
                className={
                  formErrorsSecret.client_secret
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrorsSecret.client_secret || "z"}
              </p>
            </label>

            <Button size="large" type="submit" disabled={disableButtonSecret}>
              {loginFormContent.verify_button}
            </Button>
          </form>
        </>
      ) : (
        <>
          <p className="text-3xl font-bold text-center">
            {loginFormContent.title_login}
          </p>
          <p className="mt-2 mb-4 text-xl text-slate-400 text-center">
            {loginFormContent.login_desc_1}
            <button
              onClick={() => changeForm("register")}
              className="text-gbci-accent font-bold"
            >
              {loginFormContent.login_desc_2}
            </button>{" "}
            {loginFormContent.login_desc_3}
          </p>

          <form
            className="w-full flex flex-col gap-4"
            onSubmit={handleSubmitRequest}
          >
            <label className="text-slate-400 font-bold flex flex-col gap-2">
              {loginFormContent.login_email_label}
              <input
                className="border-2 rounded-lg p-2 w-full"
                placeholder={loginFormContent.login_email_address}
                type="text"
                id="username"
                value={formData.username}
                onChange={handleChange}
                autoFocus={true}
              />
              <p
                className={
                  formErrors.username
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.username || "z"}
              </p>
            </label>

            <label className="text-slate-400 font-bold flex flex-col gap-2">
              {loginFormContent.login_password_label}
              <input
                className="border-2 rounded-lg p-2 w-full"
                placeholder={loginFormContent.login_password}
                type={showPassword ? "text" : "password"}
                id="password"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="inset-y-0 right-0 px-1 flex items-center focus:outline-none"
              >
                {showPassword
                  ? loginFormContent.hide_password
                  : loginFormContent.show_password}
              </button>
              <p
                className={
                  formErrors.password
                    ? "text-red-500"
                    : "text-transparent select-none"
                }
              >
                {formErrors.password || "z"}
              </p>
            </label>

            <div className="flex justify-between flex-col-reverse md:flex-row gap-6">
              <label className="text-slate-400 font-bold flex flex-col gap-2 mb-4">
                <div className="flex flex-row items-center">
                  <div className="relative flex">
                    <input
                      type="checkbox"
                      className="absolut inset-0 w-6 h-6 appearance-none border-2 rounded-md border-gbci-primary bg-white checked:bg-gbci-primary"
                      checked={keepLoggedIn}
                      onChange={(e) => setKeepLoggedIn(e.target.checked)}
                    />
                    {keepLoggedIn && (
                      <svg
                        className="absolute inset-0 w-6 h-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <polyline
                          points="20 6 9 17 4 12"
                          strokeWidth={2}
                        ></polyline>
                      </svg>
                    )}
                  </div>
                  <div className="text-slate-400 font-bold pl-4">
                    {loginFormContent.keep_login}
                  </div>
                </div>
              </label>
              <div className=" text-slate-400 font-bold text-right">
                <button
                  className="text-gbci-accent"
                  onClick={() => changeForm("recovery")}
                >
                  {loginFormContent.forgot_password}
                </button>
              </div>
            </div>

            <Button size="large" type="submit" disabled={disableButton}>
              {loginFormContent.verify_button}
            </Button>
          </form>
        </>
      )}

      {showAlert !== Alerts.NO_ALERT && (
        <div className="mt-8 p-4 text-red-400 bg-red-100 border border-red-400 rounded-md">
          <p>
            {showAlert == Alerts.USER_NOT_REGISTERED
              ? loginFormContent.alert_1
              : showTokenfield
                ? showAlert == Alerts.WRONG_PASS
                  ? loginFormContent.alert_4
                  : loginFormContent.alert_3
                : showAlert == Alerts.WRONG_PASS
                  ? loginFormContent.alert_2
                  : loginFormContent.alert_3}
          </p>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
