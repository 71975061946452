"use client";
import { FC, useState } from "react";
import Modal, { ModalProps } from "../common/modal";
import LoginForm from "./login-form";
import RegisterForm from "./register-form";
import { useRouter } from "next/navigation";
import RecoveryForm from "./recovery-form";
import VerifyForm from "./verify-form";
import ResendForm from "./resend-form";

interface SignInModalProps extends Pick<ModalProps, "handleClose"> {}

export interface FormProps {
  changeForm: (
    form: "login" | "register" | "recovery" | "verify" | "resend",
  ) => void;
  onSuccess: () => void;
}

const SignInModal: FC<SignInModalProps> = ({ handleClose }) => {
  const router = useRouter();
  const [formToDisplay, setFormToDisplay] = useState<
    "login" | "register" | "recovery" | "verify" | "resend"
  >("login");

  const changeForm = (
    form: "login" | "register" | "recovery" | "verify" | "resend",
  ) => {
    setFormToDisplay(form);
  };

  const onLoginSubmit = () => {
    handleClose();
  };

  const onRegisterSubmit = () => {
    // handleClose();
    setFormToDisplay("login");
    // setFormToDisplay("verify");
    // router.push("/profile");
  };

  const onResendSubmit = () => {
    setFormToDisplay("verify");
    // router.push("/profile");
    // alert("Recovery email sent");
  };

  const onVerifySubmit = () => {
    setFormToDisplay("login");
    // handleClose();
    // router.push("/profile");
  };

  const onRecoverySubmit = () => {
    // alert("Recovery email sent");
  };

  return (
    <Modal handleClose={handleClose} size="small">
      {formToDisplay === "login" ? (
        <LoginForm changeForm={changeForm} onSuccess={onLoginSubmit} />
      ) : formToDisplay === "register" ? (
        <RegisterForm changeForm={changeForm} onSuccess={onRegisterSubmit} />
      ) : formToDisplay === "recovery" ? (
        <RecoveryForm changeForm={changeForm} onSuccess={onRecoverySubmit} />
      ) : formToDisplay === "verify" ? (
        <VerifyForm changeForm={changeForm} onSuccess={onVerifySubmit} />
      ) : formToDisplay === "resend" ? (
        <ResendForm changeForm={changeForm} onSuccess={onResendSubmit} />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default SignInModal;
