interface loginFormES {
  title_section_verify: string;
  verify_desc: string;
  verification_code_label: string;
  verify_code: string;
  verify_button: string;

  error_code: string;

  title_login: string;
  login_desc_1: string;
  login_desc_2: string;
  login_desc_3: string;
  login_email_label: string;
  login_email_address: string;
  login_password_label: string;
  login_password: string;
  show_password: string;
  hide_password: string;
  keep_login: string;
  forgot_password: string;

  alert_1: string;
  alert_2: string;
  alert_3: string;
  alert_4: string;

  error_email_1: string;
  error_email_2: string;

  error_password_1: string;
}

export const loginFormES: loginFormES = {
  title_section_verify: "Verificar inicio de sesión",
  verify_desc:
    "Por favor, revisa tu correo electrónico para el código de verificación.",
  verification_code_label: "CÓDIGO DE VERIFICACIÓN:",
  verify_code: "Código de verificación",
  verify_button: "Verificar inicio de sesión",

  error_code: "Por favor, ingresa tu código de verificación.",

  title_login: "Iniciar sesión",
  login_desc_1: "Ingresa tus credenciales a continuación o ",
  login_desc_2: "regístrate",
  login_desc_3: "para una nueva cuenta.",
  login_email_label: "CORREO ELECTRÓNICO:",
  login_email_address: "Correo electrónico",
  login_password_label: "CONTRASEÑA:",
  login_password: "Contraseña",
  show_password: "Mostrar contraseña",
  hide_password: "Ocultar contraseña",
  keep_login: "Recuérdame",
  forgot_password: "¿Olvidaste tu contraseña?",

  alert_1: "El usuario no está registrado, por favor regístrate.",
  alert_2:
    "El correo electrónico o la contraseña no son correctos, por favor intenta de nuevo.",
  alert_3: "Error del servidor. Por favor, intenta de nuevo.",
  alert_4: "Código de verificación no es correcto, intenta de nuevo.",

  error_email_1: "Por favor, ingresa tu correo electrónico.",
  error_email_2:
    "Por favor, ingresa una dirección de correo electrónico válida.",

  error_password_1: "Por favor, ingresa tu contraseña.",
};

interface passwordFormES {
  title_recovery: string;
  recovery_desc: string;
  recovery_email_label: string;
  recovery_email_address: string;

  login_account_1: string;
  login_account_2: string;
  login_account_3: string;

  button_request: string;

  alert_1: string;
  alert_2: string;
  alert_3: string;
  alert_4: string;

  error_email_1: string;
  error_email_2: string;
}

export const passwordFormES: passwordFormES = {
  title_recovery: "Recuperación de contraseña",
  recovery_desc:
    "Ingresa tu correo electrónico para solicitar un restablecimiento de contraseña.",
  recovery_email_label: "CORREO ELECTRÓNICO:",
  recovery_email_address: "Correo electrónico",

  login_account_1: "¿Ya tienes tu contraseña? ",
  login_account_2: "inicia sesión",
  login_account_3: "con tu cuenta.",

  button_request: "Enviar solicitud",

  alert_1: "Usuario no encontrado.",
  alert_2: "Usuario no encontrado.",
  alert_3: "¡Solicitud de restablecimiento de contraseña enviada!",
  alert_4: "Error del servidor. Por favor, intenta de nuevo.",

  error_email_1: "Por favor, ingresa tu correo electrónico.",
  error_email_2:
    "Por favor, ingresa una dirección de correo electrónico válida.",
};

interface registerFormES {
  error_name: string;
  error_last_name: string;
  error_email_1: string;
  error_email_2: string;
  error_password_1: string;
  error_password_2: string;
  error_password_3: string;
  error_password_4: string;
  error_password_5: string;
  error_password_6: string;
  error_confirm_password_1: string;
  error_confirm_password_2: string;
  error_phone_number_1: string;
  error_phone_number_2: string;

  error_verification: string;

  verify_account_title: string;
  verify_account_desc: string;
  verification_code_label: string;
  verification_code: string;
  button_verify: string;

  register_title: string;
  register_desc_1: string;
  register_desc_2: string;
  register_desc_3: string;
  register_name_label: string;
  register_name: string;
  register_last_name_label: string;
  register_last_name: string;
  register_phone_number_label: string;
  register_phone_number: string;
  register_email_label: string;
  register_email: string;
  register_password_label: string;
  register_password: string;
  register_confirm_password_label: string;
  register_confirm_password: string;
  show_password: string;
  hide_password: string;
  verify_desc_1: string;
  verify_desc_2: string;
  button_create: string;
  alert_1: string;
  alert_2: string;
  alert_3: string;
  alert_4: string;
}

export const registerFormES: registerFormES = {
  error_name: "Por favor, ingresa tu nombre.",
  error_last_name: "Por favor, ingresa tu apellido.",
  error_email_1: "Por favor, ingresa tu correo electrónico.",
  error_email_2:
    "Por favor, ingresa una dirección de correo electrónico válida.",
  error_password_1: "Por favor, ingresa tu contraseña.",
  error_password_2: "La contraseña debe tener al menos 8 caracteres.",
  error_password_3: "La contraseña debe contener al menos una letra minúscula.",
  error_password_4: "La contraseña debe contener al menos una letra mayúscula.",
  error_password_5: "La contraseña debe contener al menos un dígito.",
  error_password_6:
    "La contraseña debe contener al menos uno de los siguientes símbolos: @$!?#%*?&",
  error_confirm_password_1: "Por favor, confirma tu contraseña.",
  error_confirm_password_2: "La contraseña no es la misma.",
  error_phone_number_1: "Por favor, ingresa tu teléfono.",
  error_phone_number_2:
    "El número de teléfono debe tener al menos 10 caracteres.",
  error_verification: "Por favor, ingresa tu código de verificación.",
  verify_account_title: "Verificar cuenta",
  verify_account_desc:
    "Por favor, revisa tu correo electrónico para obtener el código de verificación y verifica tu correo para finalizar la creación de tu cuenta.",
  verification_code_label: "CÓDIGO DE VERIFICACIÓN:",
  verification_code: "Código de verificación",
  button_verify: "Verificar cuenta",
  register_title: "Registrarse",
  register_desc_1: "Crea tu cuenta o ",
  register_desc_2: "inicia sesión",
  register_desc_3: "si ya tienes una cuenta",
  register_name_label: "NOMBRE:",
  register_name: "Nombre",
  register_last_name_label: "APELLIDO:",
  register_last_name: "Apellido",
  register_phone_number_label: "NÚMERO DE TELÉFONO:",
  register_phone_number: "Número de teléfono",
  register_email_label: "CORREO ELECTRÓNICO:",
  register_email: "Correo electrónico",
  register_password_label: "CONTRASEÑA:",
  register_password: "Contraseña",
  register_confirm_password_label: "CONFIRMAR CONTRASEÑA:",
  register_confirm_password: "Confirmar contraseña",
  show_password: "Mostrar contraseña",
  hide_password: "Ocultar contraseña",
  verify_desc_1: "¿Ya estás registrado? ",
  verify_desc_2: "Verificar cuenta",
  button_create: "Crear cuenta",
  alert_1: "El usuario ya existe. Por favor, inicia sesión.",
  alert_2: "El código de verificación no es correcto, inténtalo de nuevo.",
  alert_3: "Error en el servidor. Por favor, intenta nuevamente.",
  alert_4: "Error en el servidor. Por favor, intenta nuevamente.",
};

interface resendFormES {
  error_email_1: string;
  error_email_2: string;
  resend_title: string;
  resend_desc: string;
  resend_email_label: string;
  resemd_email: string;
  button_resend: string;
  alert_1: string;
  alert_2: string;
}

export const resendFormES: resendFormES = {
  error_email_1: "Por favor, ingresa tu correo electrónico.",
  error_email_2:
    "Por favor, ingresa una dirección de correo electrónico válida.",
  resend_title: "Verificar cuenta",
  resend_desc:
    "Proporciona tu correo electrónico para re-enviar el código de verificación.",
  resend_email_label: "CORREO ELECTRÓNICO:",
  resemd_email: "Correo electrónico",
  button_resend: "Enviar código de verificación",
  alert_1: "El usuario no existe, por favor regístrate.",
  alert_2: "Error en el servidor. Por favor, intenta nuevamente.",
};

interface verifyFormES {
  error_email_1: string;
  error_email_2: string;
  error_verify_code: string;
  verify_title: string;
  verify_desc: string;
  verify_email_label: string;
  verify_email: string;
  verify_code_label: string;
  verify_code: string;
  button_verify: string;
  alert_1: string;
  alert_2: string;
  alert_3: string;
}

export const verifyFormES: verifyFormES = {
  error_email_1: "Por favor, ingresa tu correo electrónico.",
  error_email_2:
    "Por favor, ingresa una dirección de correo electrónico válida.",
  error_verify_code: "Por favor, ingresa tu código de verificación.",
  verify_title: "Verificar cuenta",
  verify_desc:
    "Por favor, revisa tu correo electrónico para el código de verificación y verifica tu correo electrónico para terminar de crear tu cuenta.",
  verify_email_label: "CORREO ELECTRÓNICO:",
  verify_email: "Correo electrónico",
  verify_code_label: "CÓDIGO DE VERIFICACIÓN:",
  verify_code: "Código de verificación",
  button_verify: "Verificar cuenta",
  alert_1: "El usuario no existe, por favor regístrate.",
  alert_2:
    "El código de verificación es incorrecto, por favor intenta nuevamente.",
  alert_3: "Error en el servidor. Por favor, intenta nuevamente.",
};
