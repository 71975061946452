interface cookiesBannerES {
  cookies_desc_1: string;
  cookies_desc_2: string;
  cookies_desc_3: string;
  button_accept: string;
  button_read: string;
}

export const cookiesBannerES: cookiesBannerES = {
  cookies_desc_1:
    "Este sitio web utiliza cookies de terceros para mejorar la experiencia del usuario. Puedes leer más sobre ellas en nuestra ",
  cookies_desc_2: " política de privacidad",
  cookies_desc_3:
    ". ¿Aceptas los términos y condiciones con el uso de cookies?",
  button_accept: "Aceptar cookies",
  button_read: "Leer más",
};

interface cookiesModalES {
  header: string;
  date: string;
  desc: string;
  sub_title_1: string;
  text_1_1: string;
  text_1_2: string;
  text_1_3: string;
  sub_title_2: string;
  text_2_1: string;
  text_2_2: string;
  text_2_3: string;
  text_2_4: string;
  sub_title_3: string;
  text_3_1: string;
  text_3_2: string;
  sub_title_4: string;
  text_4_1: string;
  text_4_2: string;
  text_4_3: string;
  sub_title_5: string;
  text_5_1: string;
  sub_title_6: string;
  text_6_1: string;
  sub_title_7: string;
  text_7_1: string;
  sub_title_8: string;
  text_8_1: string;
  button_close: string;
}

export const cookiesModalES: cookiesModalES = {
  header: "Política de Privacidad",
  date: "Fecha de entrada en vigor: 7 de julio de 2024",
  desc: "Gracias por visitar el sitio web del Grupo GBCI (‘nosotros’, ‘nuestro’, ‘nos’). Tu privacidad es importante para nosotros. Esta Política de Privacidad explica cómo recopilamos, usamos, divulgamos y protegemos tu información cuando visitas nuestro sitio web [www.gbcigroup.com], incluidos otros medios, canales de medios, sitio web móvil o aplicación móvil relacionados o conectados con el sitio web. Por favor, lee esta política de privacidad detenidamente. Si no estás de acuerdo con los términos de esta política de privacidad, por favor no accedas al sitio web.",

  sub_title_1: "Información que recopilamos",
  text_1_1:
    "Podemos recopilar información sobre ti de diversas formas. La información que podemos recopilar en el sitio web incluye:",
  text_1_2:
    "- Datos personales: Información personal identificable, como tu nombre, nombre de la empresa, dirección de la empresa, dirección de correo electrónico e información sobre tu negocio. Toda esta información nos la proporcionas voluntariamente cuando eliges participar en diversas actividades relacionadas con el sitio web, como solicitudes de contacto o solicitudes de resultados de evaluaciones.",
  text_1_3:
    "- Datos derivados: Información que nuestros servidores recopilan automáticamente cuando accedes al sitio web, como tu dirección IP, tipo de navegador, sistema operativo, horarios de acceso y las páginas que has visitado justo antes y después de acceder al sitio web.",

  sub_title_2: "Uso de tu información",
  text_2_1:
    "Tener información precisa sobre ti nos permite ofrecerte una experiencia fluida, eficiente y personalizada. Específicamente, podemos usar la información recopilada sobre ti a través del sitio web para:",
  text_2_2:
    "- Compilar datos estadísticos anónimos y análisis para su uso interno o con terceros.",
  text_2_3: "- Mejorar la funcionalidad de nuestro sitio web.",
  text_2_4:
    "- Monitorear y analizar el uso y las tendencias para mejorar tu experiencia en el sitio web.",

  sub_title_3: "Divulgación de tu información",
  text_3_1:
    "Podemos compartir la información que hemos recopilado sobre ti en ciertas situaciones. Tu información puede ser divulgada de la siguiente manera:",
  text_3_2:
    "- Por ley o para proteger derechos: Si creemos que la divulgación de información sobre ti es necesaria para responder a un proceso legal, investigar o subsanar posibles violaciones de nuestras políticas, o para proteger los derechos, propiedad y seguridad de otras personas, podemos compartir tu información según lo permitido o requerido por cualquier ley, norma o regulación aplicable.",

  sub_title_4: "Proveedores de servicios de terceros",
  text_4_1:
    "Podemos usar proveedores de servicios de terceros para servir varios aspectos del sitio web. El uso de tu información personal por parte de cada proveedor de servicios de terceros está determinado por sus respectivas políticas de privacidad.",
  text_4_2:
    "- Videos de YouTube: Insertamos videos de nuestro canal oficial de YouTube utilizando el modo de privacidad mejorado de YouTube. Este modo puede establecer cookies en tu computadora una vez que hagas clic en el reproductor de video de YouTube, pero YouTube no almacenará información personal identificable para las reproducciones de videos incrustados utilizando el modo de privacidad mejorado.",
  text_4_3:
    "- LinkedIn Insight Tag: Usamos la etiqueta de seguimiento de LinkedIn (LinkedIn Insight Tag) para rastrear conversiones, redirigir visitantes del sitio web y obtener información sobre cómo los miembros de LinkedIn usan nuestro sitio web. La etiqueta de LinkedIn permite la recopilación de metadatos como la dirección IP, la marca de tiempo y eventos (por ejemplo, vistas de página). Todos los datos están cifrados. LinkedIn no comparte datos personales con el propietario del sitio web; solo proporciona informes agregados sobre la audiencia del sitio web y el rendimiento de los anuncios.",

  sub_title_5: "Seguridad de tu información",
  text_5_1:
    "Usamos medidas de seguridad administrativas, técnicas y físicas para ayudar a proteger tu información personal. Si bien hemos tomado medidas razonables para asegurar la información personal que nos proporcionas, ten en cuenta que, a pesar de nuestros esfuerzos, ninguna medida de seguridad es perfecta o impenetrable, y ningún método de transmisión de datos puede garantizarse contra cualquier tipo de interceptación o uso indebido.",

  sub_title_6: "Política para niños",
  text_6_1:
    "No solicitamos conscientemente información de niños menores de 13 años ni comercializamos con ellos. Si nos enteramos de que hemos recopilado información personal de un niño menor de 13 años sin verificar el consentimiento de los padres, eliminaremos esa información lo más rápido posible.",

  sub_title_7: "Cambios en esta política de privacidad",
  text_7_1:
    "Podemos actualizar esta Política de Privacidad de vez en cuando para reflejar, por ejemplo, cambios en nuestras prácticas o por otras razones operativas, legales o regulatorias.",

  sub_title_8: "Contáctanos",
  text_8_1:
    "Si tienes preguntas o comentarios sobre esta Política de Privacidad, por favor contáctanos a este correo electrónico: contact@gbcigroup.com. También puedes llamarnos a nuestro teléfono de contacto: +52 833 160 8894.",

  button_close: "Cerrar",
};
