import { FC, useEffect, useState } from "react";
import Modal, { ModalProps } from "../common/modal";
import Button from "../common/button";
import { cookiesModalEN } from "@/assets/dictionaries/en/cookies-content";
import { cookiesModalES } from "@/assets/dictionaries/es/cookies-content";

interface PrivacyPolicyModal extends Pick<ModalProps, "handleClose"> {}

const PrivacyPolicyModal: FC<PrivacyPolicyModal> = ({ handleClose }) => {
  const [cookiesContent, setCookiesContent] = useState(cookiesModalEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setCookiesContent(cookiesModalES);
        break;
      case "EN":
      default:
        setCookiesContent(cookiesModalEN);
        break;
    }
  }, []); // Only run on mount

  return (
    <Modal
      handleClose={handleClose}
      size="large"
      header={cookiesContent.header}
    >
      <section>
        <p className="text-xl mb-2">{cookiesContent.date}</p>
        <p className="text-base mb-4">{cookiesContent.desc}</p>
        <p className="text-xl mb-2">{cookiesContent.sub_title_1}</p>
        <p className="text-base mb-2">{cookiesContent.text_1_1}</p>
        <p className="text-base mb-2">{cookiesContent.text_1_2}</p>
        <p className="text-base mb-4">{cookiesContent.text_1_3}</p>

        <p className="text-xl mb-2">{cookiesContent.sub_title_2}</p>
        <p className="text-base mb-2">{cookiesContent.text_2_1}</p>
        <p className="text-base mb-2">{cookiesContent.text_2_2}</p>
        <p className="text-base mb-2">{cookiesContent.text_2_3}</p>
        <p className="text-base mb-4">{cookiesContent.text_2_4}</p>

        <p className="text-xl mb-2">{cookiesContent.sub_title_3}</p>
        <p className="text-base mb-2">{cookiesContent.text_3_1}</p>
        <p className="text-base mb-4">{cookiesContent.text_3_2}</p>

        <p className="text-xl mb-2">{cookiesContent.sub_title_4}</p>
        <p className="text-base mb-2">{cookiesContent.text_4_1}</p>
        <p className="text-base mb-2">{cookiesContent.text_4_2}</p>
        <p className="text-base mb-4">{cookiesContent.text_4_3}</p>

        <p className="text-xl mb-2">{cookiesContent.sub_title_5}</p>
        <p className="text-base mb-4">{cookiesContent.text_5_1}</p>

        <p className="text-xl mb-2">{cookiesContent.sub_title_6}</p>
        <p className="text-base mb-4">{cookiesContent.text_6_1}</p>

        <p className="text-xl mb-2">{cookiesContent.sub_title_7}</p>
        <p className="text-base mb-4">{cookiesContent.text_7_1}</p>

        <p className="text-xl mb-2">{cookiesContent.sub_title_8}</p>
        <p className="text-base mb-4">{cookiesContent.text_8_1}</p>

        <div className="flex justify-center">
          <div>
            <Button onClick={handleClose}>{cookiesContent.button_close}</Button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default PrivacyPolicyModal;
